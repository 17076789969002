import React from 'react'
import './styles.css'

const NotFound: React.FC<{}> = () => {
  return (
    <main>
      <p>
        Bad config! If you've arrived here, there are some settings missing.
        <br />
        We need to know the <b>client</b> and the <b>OS</b> in order to continue
      </p>
    </main>
  )
}

export default NotFound
