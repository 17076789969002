import React from 'react'
import { Route, Switch } from 'react-router-dom'
import './App.css'
import DownloadButton from './components/downloadButton'
import LazyLoadImage from './components/lazyLoadImage'
import Navigation from './components/navigation'
import NotFound from './components/notFound'
import PageWrapper from './components/pageWrapper'

function App() {
  return (
    <Switch>
      <Route
        path="/:client?/:os?/:page?"
        render={({ match: { params } }) => {
          const { os = 'notFound', page = 0, client = 'notFound' } = params
          if ((os !== 'windows' && os !== 'macos') || client === 'notFound') {
            return <NotFound />
          }
          const totalPages = 5
          const showDownloadButton = Number(page) === 0
          return (
            <div className="app">
              <header>
                <h1>IMPORTANT!!!</h1>
                <h2>BEFORE PROCEEDING, PLEASE READ ALL INSTRUCTIONS!</h2>
              </header>
              <PageWrapper>
                {showDownloadButton ? (
                  <DownloadButton os={os} client={client} />
                ) : (
                  <LazyLoadImage os={os} page={Number(page)} />
                )}
              </PageWrapper>

              <Navigation os={os} current={Number(page)} client={client} total={totalPages} />
            </div>
          )
        }}
      />
    </Switch>
  )
}

export default App
