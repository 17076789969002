const en = {
  windowsTitle2: 'Click on the text',
  windowsTitle3: 'Click the "More Info" link',
  windowsTitle4: 'Click on the "Run anyway" button',
  windowsTitle5: 'It is installing, please wait',
  windowsTitle6: 'The icon is now on your desktop',

  windowsCaption2: 'The installer will open once it is downloaded',
  windowsCaption3: 'It will show you more options',
  windowsCaption4: 'This will start the installation',
  windowsCaption5: 'It will not take long!',
  windowsCaption6: 'Double click on it and enjoy!',

  macosTitle2: 'Click on the text',
  macosTitle3: 'Drag the icon to the Application folder',
  macosTitle4: 'Go to the Application folder',
  macosTitle5: 'Right click on the app name',
  macosTitle6: 'Click on the "open" button',

  macosCaption2: 'The disk image will open once it is downloaded',
  macosCaption3: 'This will copy the app',
  macosCaption4: 'And find the rainbow app',
  macosCaption5: 'And select the "open" option',
  macosCaption6: 'Enjoy your app!',
}

let arrayMessages: any[any] = []
arrayMessages['en'] = en

export default arrayMessages
