export function flattenMessages(nestedMessages: any, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages: any, key) => {
    let value = nestedMessages[key]
    let prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      messages[prefixedKey] = value
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
}

export const getParameterLanguage = (): string => {
  const validLanguages = ['en', 'es', 'pt', 'vi']
  const paramLang = getParameterByName('lang')
  return validLanguages.indexOf(paramLang) < 0 ? 'en' : paramLang
}

export const getParameterByName = (name: string, url?: string): string => {
  if (!url) {
    url = window.location.href
  }
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) {
    // console.info(`extracting ${name} from querystring: No Data`)
    return ''
  }
  const value = results[2]
  if (!value) {
    // console.info(`extracting ${name} from querystring: No Data`)
    return ''
  }
  const result = decodeURIComponent(value.replace(/\+/g, ' '))
  // console.info(`extracting ${name} from querystring: "${result}"`)
  return result
}