import React from 'react'
import {FormattedMessage} from 'react-intl'
import './styles.css'

interface LazyLoadImageProps {
  os: string
  page: number
}

interface LazyLoadImageState {
  src: string
  err: string
}

class LazyLoadImage extends React.Component<LazyLoadImageProps, LazyLoadImageState> {
  constructor(props: LazyLoadImageProps) {
    super(props)
    this.state = {
      src: '',
      err: '',
    }
  }

  componentDidMount = () => {
    this.loadImage()
  }

  shouldComponentUpdate(nextProps: LazyLoadImageProps, nextState: LazyLoadImageState) {
    return this.state.src !== nextState.src
    || this.props.os !== nextProps.os 
    || this.props.page !== nextProps.page 
  }

  componentDidUpdate = () => {
    this.loadImage()
  }

  loadImage = async () => {
    const { os, page } = this.props
    try {
      const { default: src } = await import(`../../assets/images/${os}_${page}.png`)
      this.setState({ src })
    } catch (err) {
      this.setState({ err: err.toString() })
    }
  }

  render() {
    const { os, page } = this.props
    return (
      <div className="step_wrapper">
        <FormattedMessage id={`${os}Title${page + 1}`}>{(txt) => <h1>{`${page + 1}. ${txt}`}</h1>}</FormattedMessage>
        <img src={this.state.src} alt="example.png" />
        <FormattedMessage id={`${os}Caption${page + 1}`}>{(txt) => <p>{txt}</p>}</FormattedMessage>
      </div>
    )
  }
}

export default LazyLoadImage
